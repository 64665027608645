<template>
	<div class="d-flex justify-content-around">
			
		<div class="mb-20 mw-70">
			<b-form @submit.prevent="cargoScan">
				<div class="row">
					<div class="col-md-6">
						<b-form-group
							id="input-group-1"
							label="LM AWB"
							label-size="sm"
						>
							<b-form-input
								v-model="form.lm_awb"
								type="text"
								ref="lm_awb"
								placeholder="Type and Scan LM AWB"
								size="sm"
								required
							></b-form-input>
						</b-form-group>
					</div>

					<div class="col-md-4">
						<b-form-group
							id="upload-image"
							label="Upload Image (Optional)"
							label-size="sm"
						>
	                        <b-form-file 
	                        	class="form-input" 
	                        	accept=".png, .jpg, .jpeg" 
	                        	v-model="form.bag_img" 
	                            placeholder="Choose a file or drop it here..." 
	                            drop-placeholder="Drop file here..."
	                        ></b-form-file>
	                    </b-form-group>
					</div>

				</div>

				<div class="row">
					<div class="col-md-3 col-6">
						<b-form-group
							id="input-group-1"
							label="Length (cm)"
							label-size="sm"
						>
							<b-form-input
								v-model="form.length"
								placeholder="Length"
								type="text"
								size="sm"
								min="1"
								required
							></b-form-input>
						</b-form-group>
					</div>

					<div class="col-md-3 col-6">
						<b-form-group
							id="input-group-1"
							label="Width (cm)"
							label-size="sm"
						>
							<b-form-input
								v-model="form.width"
								placeholder="Width"
								type="text"
								size="sm"
								min="1"
								required
							></b-form-input>
						</b-form-group>
					</div>

					<div class="col-md-3 col-6">
						<b-form-group
							id="input-group-1"
							label="Height (cm)"
							label-size="sm"
						>
							<b-form-input
								v-model="form.height"
								placeholder="Height"
								type="text"
								size="sm"
								min="1"
								required
							></b-form-input>
						</b-form-group>
					</div>

					<div class="col-md-3 col-6">
						<b-form-group
							id="input-group-1"
							label="Weight (kg)"
							label-size="sm"
						>
							<b-form-input
								v-model="form.weight"
								placeholder="Weight"
								type="text"
								size="sm"
								min="1"
								required
							></b-form-input>
						</b-form-group>
					</div>

				</div>

				<div class="row mt-3">
					<div class="col-md-2 col-6">
						<button 
							type="submit" 
							class="btn btn-primary btn-block"
							:disabled="isBusy"
						>
							Submit 
							<b-spinner v-if="isBusy" small class="ml-1"></b-spinner>
						</button>
					</div>
				</div>

			</b-form>
		</div>

	</div>
</template>

<script>

export default {

	name: 'CargoScan',
	title: 'CargoScan',

	data() {
		return {
			form: {},
			isBusy: false,
		}
	},


	methods: {

		async cargoScan() {

			this.isBusy = true;

			let fd = new FormData();

            for (const key of Object.keys(this.form)) {
             	fd.append(key, this.form[key]);
            }
        
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

			try {
				
				let res = await this.axios.post('/inscan_cargo', fd, config);

				if(res.data.success) {
					this.popToast('Success', res.data.message, 'success')
					this.form = {};
				}
				else {
					this.popToast('Failed!', res.data.message, 'danger')
				}
			
				this.isBusy = false;

			}
			catch (err) {
				this.isBusy = false;
				this.popToast('failed', 'Failed', err)
			}

		}


	},



}
</script>
<style lang="scss" scoped>
	.mw-80 {
		max-width: 80%!important;
		min-width: 80%!important;
	}

	.mw-70 {
		max-width: 70%!important;
		min-width: 70%!important;
	}

</style>
